<template>
  <div>
    <h2 class="title_categories">Контакты</h2>
    <table>
      <tr>
        <td>Заголовок</td>
        <td>Описание</td>
        <td class="drop-icon"></td>
      </tr>
      <tr>
        <td>Пресс-служба Общественной Палаты</td>
        <td>{{ hotLine }}</td>
        <td class="drop-icon">
            <router-link to="connect_contacts/hotLine/edit"> Редактирование </router-link>
        </td>
      </tr>
      <tr>
        <td>Телефон для справок</td>
        <td>{{ full_name }}</td>
        <td class="drop-icon">
            <router-link to="connect_contacts/certificate_admission/edit"> Редактирование </router-link>
        </td>
      </tr>
      <tr>
        <td>Интернет-приемная Общественной палаты РФ</td>
        <td>{{ infoPhone }}</td>
        <td class="drop-icon">
            <router-link to="connect_contacts/information_service/edit"> Редактирование </router-link>
        </td>
      </tr>
      <tr>
        <td>Общественная палата Российской Федерации</td>
        <td>{{ getInternetReception.address }}</td>
        <td class="drop-icon">
            <router-link to="connect_contacts/internet_reception/edit"> Редактирование </router-link>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ConnectContacts',
  data () {
    return {
      full_name: '',
      hotLine: '',
      infoPhone: ''
    }
  },
  created () {
    this.$store.dispatch('setHotLine')
    this.$store.dispatch('setPhoneCertificateAdmission')
    this.$store.dispatch('setInformationService')
    this.$store.dispatch('setInternetReception')
  },
  computed: {
    getHotLine () {
      return this.$store.getters.getHotLine
    },
    getCertificateAdmission () {
      return this.$store.getters.getPhoneCertificateAdmission ? this.$store.getters.getPhoneCertificateAdmission : []
    },
    getInformationService () {
      return this.$store.getters.getInformationService
    },
    getInternetReception () {
      return this.$store.getters.getInternetReception
    }
  },
  watch: {
    getHotLine () {
      this.hotLine = this.$store.getters.getHotLine.phone
    },
    getCertificateAdmission () {
      this.full_name = this.getCertificateAdmission.contacts[0].full_name
    },
    getInformationService () {
      this.infoPhone = this.$store.getters.getInformationService.blocs[0].data[0]
    }
  }
}
</script>

<style scoped lang="scss">
  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
